<template>
  <div class="emailContainer">
    <p style="font-size: 24px">Step 1 : 请输入您的邮箱或电话号码</p>
    <div class="emailForm">
      <el-form :model="inputEmailForm" class="demo-dynamic" @submit.native.prevent>
        <el-form-item
          :rules="[
       { required: true, message: '请输入邮箱地址', trigger: 'blur' }
    ]"
        >
          <el-input prefix-icon="el-icon-message" v-model="inputEmailForm.getemail" ref="getEmail" style="min-width: 200px"
                    v-on:change="passEmail"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterStepOne',
  data: function () {
    return {
      inputEmailForm: {
        getemail: ''
      }
    }
  },
  methods: {
    passEmail () {
      this.$emit('func', this.inputEmailForm.getemail)
    }
  }
}
</script>

<style scoped>
.emailContainer{
  padding: 20px 30px;
  box-sizing: border-box;
}
.emailForm{
  margin: 65px 30px;
}
</style>
